import React, { useEffect } from 'react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Settings from './pages/Settings';
import { useSelector, useDispatch } from 'react-redux';
import { signInWithGoogle, listenForAuthChanges, signOut } from './redux/actions';
import UserLogs from './pages/UserLogs';
const logoURL = 'https://infaque.com/wp-content/uploads/2024/01/Infaque-Primary-Logo-RGB-Canary-768x231.png'; // Update this to the actual path of your logo

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use navigate hook
  const signedInStatus = useSelector((state) => state.signedInStatus);

  useEffect(() => {
    dispatch(listenForAuthChanges());
  }, [dispatch]);

  const handleSignIn = () => {
    console.log('Handling sign in');
    dispatch(signInWithGoogle(() => navigate('/'))); // Pass navigate function as callback
  };

  return (
    signedInStatus ? (
      <div className="flex h-screen bg-gray-200">
        <div className="flex-grow bg-white">
          <Routes>
            <Route path="/settings" element={<Settings />} />
            <Route path="/" element={<Home />} />
            <Route path="/user-logs" element={<UserLogs />} />
            {/* Add other routes here */}
          </Routes>
        </div>
      </div>
    ) : (
      <div className="flex flex-col min-h-screen bg-gray-100">
        <div className="flex justify-center mt-8">
          <img src={logoURL} alt="Infaque POS Logo" className="w-40 mb-8" />
        </div>
        <div className="flex flex-col items-center justify-center flex-grow">
          <h1 className="text-4xl font-bold mb-8">Stripe POS</h1>
          <button
            onClick={handleSignIn}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full text-xl"
          >
            Sign in with Google
          </button>
        </div>
      </div>
    )
  );
}
