import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { processPOSPaymentAction, checkPOSStatusAction, cancelPOSPaymentAction, fetchTenantID, setTargetFundraisingAction, setUserEmail } from '../redux/actions';
import settingsIcon from '../images/settings01.png';
import goodSignalIcon from '../images/green_wifi.svg';
import noSignalIcon from '../images/red_no_wifi.png';
import reloadIcon from '../images/lightBlueRefresh.png';
import checkingPOS from '../images/wifi-global.gif';

function Home() {
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.userDetails);
    const readerStatus = useSelector((state) => state.readerStatus);
    const readers = useSelector((state) => state.readers);
    const userTenant = useSelector((state) => state.userTenant);
    const awaitingPOSResponse = useSelector((state) => state.awaitingPOSResponse);
    const posStatusResponse = useSelector((state) => state.posStatus);
    const awaitingPOSStatusResponse = useSelector((state) => state.awaitingPOSStatusResponse);

    const posStatus = posStatusResponse?.readerStatus?.status;
    const logoURL = useSelector((state) => state.logoURL);

    const fundraisingTargets = useSelector((state) => state.fundraisingTargets);
    const positionOfGeneral = fundraisingTargets.findIndex(target => target.name === "General");

    const [amount, setAmount] = useState('$0.00');
    const [donorEmail, setDonorEmail] = useState('');
    const [donorName, setDonorName] = useState('');
    const [donorPhone, setDonorPhone] = useState('');
    const [emailFontSize, setEmailFontSize] = useState('1rem');
    const [nameFontSize, setNameFontSize] = useState('1rem');
    const [selectedTarget, setSelectedTarget] = useState('0');

    const handleTargetChange = (event) => {
        const targetID = fundraisingTargets[event.target.value].id;
        dispatch(setTargetFundraisingAction(targetID));
        setSelectedTarget(event.target.value);
    };

    useEffect(() => {
        if (userDetails && Object.keys(userDetails).length > 0) {
            dispatch(fetchTenantID());
        }
    }, [userDetails, dispatch]);

    useEffect(() => {
        if (fundraisingTargets && fundraisingTargets.length > 0) {
            setSelectedTarget(positionOfGeneral);
            dispatch(setTargetFundraisingAction(positionOfGeneral));
            dispatch(checkPOSStatusAction());
        }
    }, [fundraisingTargets, dispatch]);

    const handleAmountChange = (event) => {
        const value = event.target.value.replace(/[^0-9.]/g, '');
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setAmount('$' + value);
        }
    };

    const handleFocus = () => {
        if (amount === '$0.00') {
            setAmount('$');
        }
    };

    const handleBlur = () => {
        if (amount === '$' || amount === '$.') {
            setAmount('$0.00');
        } else {
            setAmount((parseFloat(amount.replace('$', '')) || 0).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }));
        }
    };

    const handlePayment = () => {
        dispatch(processPOSPaymentAction({
            amount: amount,
            email: donorEmail,
            targetID: fundraisingTargets[selectedTarget].id,
            isCause: fundraisingTargets[selectedTarget].cause
        }));
    };

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setDonorEmail(value);
        setEmailFontSize(value.length > 20 ? '0.75rem' : '1rem'); // Adjust the threshold and sizes as needed
    }

    const handleNameChange = (event) => {
        const value = event.target.value;
        setDonorName(value);
        setNameFontSize(value.length > 20 ? '0.75rem' : '1rem'); // Adjust the threshold and sizes as needed
    }

    const handlePhoneChange = (event) => {
        const value = event.target.value;
        setDonorPhone(value);
    }

    const handlePOSStatusCheck = () => {
        dispatch(checkPOSStatusAction());
    }


    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <div className="w-full flex items-center justify-between p-2 bg-gray-100" style={{ height: '75px' }}>
                    <img src={logoURL} alt="Logo" className="h-15" style={{ width: "auto", height: '60px' }} />

                    <div className="absolute left-1/2 transform -translate-x-1/2">

                    </div>
                    <Link to="/settings" className="h-6 w-6">
                        <img src={settingsIcon} alt="Settings" className="h-full w-full" />
                    </Link>
                </div>

                <div className="w-full">
                    <div className="flex justify-between items-center">
                        <h1 className="flex-grow text-center text-3xl mb-2 md:mb-4">Payments</h1>
                        <div className="flex items-center space-x-1 ">
                            {readerStatus !== 'online' && (
                                <>
                                    <img src={noSignalIcon} alt="No Signal" className="h-6 w-6 transform scale-75" />
                                    {awaitingPOSStatusResponse ? (
                                        <img src={checkingPOS} alt="Loading" className="h-6 w-6 transform scale-75" />
                                    ) : (
                                        <img onClick={handlePOSStatusCheck} src={reloadIcon} alt="Reload" className="h-6 w-6 transform scale-75" />
                                    )}
                                </>
                            )}
                            {readerStatus === 'online' && (
                                <>
                                    <img src={goodSignalIcon} alt="Good Signal" className="h-6 w-6 transform scale-75" />
                                    {awaitingPOSStatusResponse ? (
                                        <img src={checkingPOS} alt="Loading" className="h-6 w-6 transform scale-75" />
                                    ) : (
                                        <img onClick={handlePOSStatusCheck} src={reloadIcon} alt="Reload" className="h-6 w-6 transform scale-75" />
                                    )}
                                </>
                            )}
                        </div>
                    </div>




                    <div className="flex flex-col items-center space-y-3 max-w-3xl mx-auto">
                        <select
                            value={selectedTarget}
                            onChange={handleTargetChange}
                            className="border border-gray-400 bg-white text-gray-700 py-3 px-4 pr-8 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 text-lg w-full"
                        >
                            {fundraisingTargets.map((target, index) => (
                                <option key={target.id} value={index}>{target.name}</option>
                            ))}
                        </select>

                        {positionOfGeneral !== -1 && fundraisingTargets && fundraisingTargets.length > 0 &&
                            <p>
                                <a href={fundraisingTargets[selectedTarget]?.shortURL} target="_blank" rel="noopener noreferrer">
                                    To see the donation page, please click here
                                </a>
                            </p>
                        }

                        <input
                            type="text"
                            inputMode="decimal"
                            pattern="[0-9]*[.,]?[0-9]*"
                            value={amount}
                            onChange={handleAmountChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            className="mt-2 text-center rounded px-2 py-1 bg-gray-100 w-full"
                            style={{ fontSize: '3rem' }}
                            placeholder="Enter amount"
                        />


                        <div className="flex items-center justify-between w-full" style={{ height: '60px' }}>
                            <label className="text-sm md:text-lg" style={{ flex: '0 0 33%' }}>Email (optional):</label>
                            <input
                                type="text"
                                value={donorEmail}
                                onChange={handleEmailChange}
                                className="text-center border border-gray-500 rounded px-2 py-1 w-full"
                                style={{ height: '40px', fontSize: emailFontSize }}
                                placeholder="Enter Email (optional)"
                            />
                        </div>

                        <div className="flex items-center justify-between w-full" style={{ height: '60px' }}>
                            <label className="text-sm md:text-lg" style={{ flex: '0 0 33%' }}>Name (optional):</label>
                            <input
                                type="text"
                                value={donorName}
                                onChange={handleNameChange}
                                className="text-center border border-gray-500 rounded px-2 py-1 w-full"
                                style={{ height: '40px', fontSize: nameFontSize }}
                                placeholder="Enter Name (optional)"
                            />
                        </div>

                        <div className="flex items-center justify-between w-full" style={{ height: '60px', marginBottom: '1rem' }}>
                            <label className="text-sm md:text-lg" style={{ flex: '0 0 33%' }}>Phone Number (optional):</label>
                            <input
                                type="text"
                                value={donorPhone}
                                onChange={handlePhoneChange}
                                className="text-center border border-gray-500 rounded px-2 py-1 w-full"
                                style={{ height: '40px', fontSize: nameFontSize }}
                                placeholder="Enter Phone Number (optional)"
                            />
                        </div>

                        {!awaitingPOSResponse && <button onClick={handlePayment} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full mt-3 w-full">
                            Make POS Payment
                        </button>}

                        {awaitingPOSResponse && <button onClick={() => dispatch(cancelPOSPaymentAction())} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full mt-3 w-full">
                            Cancel Payment POS
                        </button>}

                        <button onClick={handlePOSStatusCheck} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-3 w-full">
                            Check POS Status
                        </button>

                        <p>Tenant Info: {userTenant}</p>
                    </div>
                </div>
            </div>
        </div>





    );
}

export default Home;
