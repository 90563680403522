import { app, auth, provider, signInWithPopup, db } from '../firebase/firebase.utils';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, getDocs, collection, onSnapshot } from "firebase/firestore";

export const increment = () => ({
    type: 'counter/increment',
});

export const clearUserDetails = () => ({
    type: 'trevor/clearUserDetails',
    payload: {}
});

export const decrement = () => ({
    type: 'counter/decrement',
});

export const setTargetFundraisingAction = (id) => ({
    type: 'user/userSelectedFundraisingTarget',
    payload: id
});


export const setUserEmail = (email) => ({
    type: 'user/setUserEmail',
    payload: email
});


export const setUserDetails = (userDetails) => {
    return async (dispatch) => {
        console.log('Inside Dispatch of user details', userDetails)
        dispatch({
            type: 'trevor/setUserDetails',
            payload: userDetails
        });
        //console.log('Now getting URL for user dashboard')
        //dispatch(getURLFromTrevorForUserDetails());
    }
}



    ;

export const listenForAuthChanges = () => {
    console.log('listening for auth changes')
    return (dispatch) => {
        auth.onAuthStateChanged(async auth => {
            console.log('auth', auth)
            if (auth) {

                const loggedInUser = { uid: auth.uid, displayName: auth.displayName, email: auth.email };
                console.log('User trying to log in', loggedInUser);
                const docRef = doc(db, "allowedUsers", loggedInUser.email);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    console.log('User is in allowedUsers collection')
                    dispatch({
                        type: 'user/signIn',
                        payload: loggedInUser
                    });
                } else {
                    console.log('User is not in allowedUsers collection');
                    dispatch(signOut());
                }








            } else {
                console.log('User is NOT signed in');
                dispatch({
                    type: 'user/signOut'
                });
            }
        });
    };
};

export const fetchAllowedUsers = () => {
    return async (dispatch) => {
        try {
            const querySnapshot = await getDocs(collection(db, "allowedUsers"));
            const users = [];
            querySnapshot.forEach((doc) => {
                users.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            dispatch({
                type: 'user/setAllowedUsers',
                payload: users
            });
        } catch (error) {
            console.error("Error fetching allowed users:", error);
        }
    };
};

export const signInWithGoogle = (callback) => {
    return (dispatch) => {
        console.log('Clicked Sign In with Google');
        signInWithPopup(auth, provider)
            .then(async (result) => {
                console.log('User is signed in with Google', result.user);
                const user = result.user;
                const loggedInUser = { uid: user.uid, displayName: user.displayName, email: user.email };
                const docRef = doc(db, "allowedUsers", loggedInUser.email);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    console.log('User is in allowedUsers collection');
                    dispatch({
                        type: 'user/signIn',
                        payload: loggedInUser, // Dispatch only serializable data
                    });
                    if (callback) callback();  // Execute callback if provided
                } else {
                    console.log('User is not in allowedUsers collection');
                    dispatch(signOut());
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const signOut = () => {
    return (dispatch) => {
        auth.signOut().then(() => {
            dispatch({
                type: 'user/signOut'
            });
        });
    };
}



export const processPOSPaymentAction = (details) => {

    const amount = parseFloat(details.amount.replace(/[$,]/g, ''));
    const email = details.email ? details.email : 'pos@infq.cc';
    const campaign_id = details.targetID;
    const isCause = details.isCause;
    console.log('Sending Payment of $', details.amount, ' to POS for donor:', details.email, ' for target:', campaign_id);





    return async (dispatch, getState) => {
        dispatch({
            type: 'user/setAwaitingPOSResponse',
            payload: true
        });
        const state = getState();
        const functions = getFunctions();

        const processPOSPayment = httpsCallable(functions, 'processPOSPaymentConnectUpdatedV2');
        // let clientAccountId = 'acct_1P3FQzK1meoYW4sX';
        // let terminalId = 'tmr_Fk1nzwB6LxeKIH';
        // let org_id = 'JHfoMzPFPGEfMLc6N2qo';
        let clientAccountId = '';
        let terminalId = '';
        let org_id = '';
        const tenant_id = state.userTenant;
        const docRef = doc(db, "tenants", tenant_id);
        const docSnap = await getDoc(docRef);
        const location_id = state.readers[0].location;


        if (docSnap.exists()) {
            console.log('Found tenant info in Firestore:', docSnap.data());
            clientAccountId = docSnap.data().clientAccountId;

            org_id = docSnap.data().org_id;
        } else {
            return { status: "Error", message: "Tenant info not found in Firestore" };
        }
        terminalId = state.readers[0].id;

        console.log('Calling POS Payment with', { email: email, amount: amount, clientAccountId: clientAccountId, terminalId: terminalId, org_id: org_id, campaign_id: campaign_id, tenant_id: tenant_id, isCause: isCause })

        try {
            const result = await processPOSPayment(
                {
                    email: email, amount: amount,
                    clientAccountId: clientAccountId,
                    terminalId: terminalId, org_id: org_id,
                    campaign_id: campaign_id,
                    tenant_id: tenant_id,
                    location_id: location_id,
                    isCause: isCause
                });
            console.log(result.data);
            dispatch({
                type: 'user/setPOSResponse',
                payload: result.data
            });


        } catch (error) {
            console.error('Error calling stripe:', error);
        }
    };
};

export const cancelPOSPaymentAction = (amount) => {
    console.log('Cancelling Payment POS')

    return async (dispatch, getState) => {
        const state = getState();


        let terminalId = '';

        const tenant_id = state.userTenant;
        const docRef = doc(db, "tenants", tenant_id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log('Found tenant info in Firestore:', docSnap.data());
            terminalId = docSnap.data().terminalId;
        } else {
            return { status: "Error", message: "Tenant info not found in Firestore" };
        }
        const functions = getFunctions();
        const cancelPOSPayment = httpsCallable(functions, 'cancelPOSPaymentConnect');

        try {
            const clientAccountId = state.clientAccountId;
            const terminalId = state.readers[0].id;
            const result = await cancelPOSPayment({ clientAccountId: clientAccountId, terminalId: terminalId });
            console.log('Result from cancel POS')
            console.log(result.data);
            dispatch({
                type: 'user/setAwaitingPOSResponse',
                payload: false
            });
        } catch (error) {
            console.error('Error calling stripe cancel POS:', error);
        }
    };
};

function reorderTargets(targets) {
    // Find the position of the "General" target
    const generalPosition = targets.findIndex(target => target.name === "General");

    // If "General" is found, remove it from its current position
    const generalTarget = generalPosition !== -1 ? targets.splice(generalPosition, 1)[0] : null;

    // Sort the targets in alphabetical order
    targets.sort((a, b) => a.name.localeCompare(b.name));

    // If "General" was found, add it back at the beginning
    if (generalTarget) {
        targets.unshift(generalTarget);
    }

    return targets;
}


export const fetchReadersForConnectedAccount = (clientAccountId) => {
    return async (dispatch, getState) => {

        const functions = getFunctions();
        const getReadersForConnectedAccount = httpsCallable(functions, 'getReadersForConnectedAccount');
        const result = await getReadersForConnectedAccount({ clientAccountId: clientAccountId });
        dispatch({ type: 'user/setReaders', payload: result.data.readers?.data });
        const readersArray = result.data.readers?.data;
        const myReader = readersArray.find(reader => reader.label === 'Infaque Reader');
        if (myReader.status === 'online') {
            dispatch({ type: 'user/setReaderStatus', payload: 'online' });
        } else {
            dispatch({ type: 'user/setReaderStatus', payload: 'offline' });
        }
    };
}

export const fetchTenantID = () => {

    return async (dispatch, getState) => {
        console.log('Fetching Tenant ID');
        const state = getState();
        const userDetails = state.userDetails;
        const email = userDetails.email;

        console.log('Fetching Tenant ID for ', email)
        const docRef = doc(db, "allowedUsers", email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            console.log('Setting Tenant ID in Redux:', docSnap.data().tenantID)
            const tenantID = docSnap.data().tenantID;

            const logoRef = doc(db, "tenants", tenantID);
            const logoSnap = await getDoc(logoRef);
            console.log('Tenant Data ', logoSnap.data())
            const logoURL = logoSnap.data().logo;
            const clientAccountId = logoSnap.data().clientAccountId;

            const collectionRef = collection(db, "tenants", tenantID, "fundraisingTargets");
            console.log('Fetching Fundraising Targets for ', tenantID, "fundraisingTargets")

            const querySnapshot = await getDocs(collectionRef);

            // Process each document


            let fundraisingTargetIDs = [];

            // Process each document
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
                fundraisingTargetIDs.push({
                    id: doc.id,
                    name: doc.data().name,
                    shortURL: doc.data().shortURL,
                    cause: doc.data()?.cause === true
                }); // Add the document ID to the array
            });
            fundraisingTargetIDs = reorderTargets(fundraisingTargetIDs);

            const payload = { tenantID: tenantID, fundraisingTargets: fundraisingTargetIDs };

            await dispatch(fetchReadersForConnectedAccount(clientAccountId));


            await dispatch({
                type: 'user/setTenantID',
                payload: payload
            });

            await dispatch({
                type: 'user/setLogoURL',
                payload: logoURL
            });

            await dispatch({
                type: 'user/setClientAccountId',
                payload: clientAccountId
            });



        } else {
            console.log("No such document!");
        }
    };
};

export const checkPOSStatusAction = () => {
    console.log('Checking POS Status')

    return async (dispatch, getState) => {
        const state = getState();
        let terminalId = '';
        dispatch({
            type: 'user/setAwaitingPOSStatusResponse',
            payload: true

        })

        const tenant_id = state.userTenant;
        const docRef = doc(db, "tenants", tenant_id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log('Found tenant info in Firestore:', docSnap.data());
            terminalId = docSnap.data().terminalId;
        } else {
            return { status: "Error", message: "Tenant info not found in Firestore" };
        }

        const functions = getFunctions();
        const checkPOSStatus = httpsCallable(functions, 'checkPOSStatus');

        try {
            const result = await checkPOSStatus({ terminalId: terminalId });
            console.log('POS Status:', result.data);
            dispatch({
                type: 'user/setPOSstatus',
                payload: result.data
            });

        } catch (error) {
            console.error('Error calling stripe POS Status:', error);
        }
    };
};

export const addReaderToConnectedAccountIfNeeded = (readerCode, clientAccountId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: 'user/setAwaitingReaderResponse',
            payload: true
        });

        const functions = getFunctions();
        const addReader = httpsCallable(functions, 'AddReaderToConnectedAccountIfNeeded');

        const result = await addReader({ clientAccountId: clientAccountId, readerCode: readerCode });
        console.log('Reader added:', result.data);
        dispatch({
            type: 'user/setReaders',
            payload: result.data.readers
        });
        dispatch({
            type: 'user/setAwaitingReaderResponse',
            payload: false
        });


    };


}

export const startListeningToUserLogs = (userId) => {
    return (dispatch) => {
        const logsRef = collection(db, "allowedUsers", userId, "logs");

        // Return the unsubscribe function
        return onSnapshot(logsRef, (snapshot) => {
            const logs = [];
            snapshot.forEach((doc) => {
                const timestamp = doc.data().timestamp;
                const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);

                logs.push({
                    id: doc.id,
                    ...doc.data(),
                    timestamp: date
                });
            });

            // Sort logs in reverse chronological order
            logs.sort((a, b) => b.timestamp - a.timestamp);

            dispatch({
                type: 'user/setUserLogs',
                payload: logs
            });
        });
    };
};
