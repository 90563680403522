// src/pages/UserLogs.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAllowedUsers, fetchUserLogs, startListeningToUserLogs } from '../redux/actions';
import settingsIcon from '../images/settings01.png';

const UserLogs = () => {
    const dispatch = useDispatch();
    const logoURL = useSelector((state) => state.logoURL);
    const allowedUsers = useSelector((state) => state.allowedUsers);
    const userLogs = useSelector((state) => state.userLogs);
    const [selectedUser, setSelectedUser] = useState('');
    const [unsubscribe, setUnsubscribe] = useState(null);

    useEffect(() => {
        dispatch(fetchAllowedUsers());
        // Cleanup function
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [dispatch]);

    const handleUserChange = (event) => {
        const userId = event.target.value;
        setSelectedUser(userId);

        // Unsubscribe from previous listener if exists
        if (unsubscribe) {
            unsubscribe();
        }

        if (userId) {
            // Start new listener and save unsubscribe function
            const unsub = dispatch(startListeningToUserLogs(userId));
            setUnsubscribe(() => unsub);
        }
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return 'No date';
        const date = timestamp instanceof Date ? timestamp : timestamp.toDate();
        return date.toLocaleString();
    };

    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <div className="w-full flex items-center justify-between p-2 bg-gray-100" style={{ height: '75px' }}>
                    <img src={logoURL} alt="Logo" className="h-15" style={{ width: "auto", height: '60px' }} />
                    <Link to="/settings" className="h-6 w-6">
                        <img src={settingsIcon} alt="Settings" className="h-full w-full" />
                    </Link>
                </div>

                <div className="w-full">
                    <h1 className="text-3xl mb-4 text-center">User Logs</h1>

                    <div className="flex flex-col items-center space-y-4">
                        <div className="w-full flex gap-2">
                            <select
                                value={selectedUser}
                                onChange={handleUserChange}
                                className="flex-grow p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="">Select a user</option>
                                {allowedUsers.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.id} - {user.role}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {selectedUser && (
                            <div className="w-full mt-4">
                                <h2 className="text-xl mb-2">Logs for {selectedUser}</h2>
                                <div className="space-y-2">
                                    {userLogs.map((logEntry, index) => (
                                        <div key={index} className="bg-white p-4 rounded-lg shadow border border-gray-200">
                                            <div className="flex justify-between items-start">
                                                <span className="text-sm text-gray-500">
                                                    {formatDate(logEntry.timestamp)}
                                                </span>
                                            </div>
                                            <div className="mt-2 text-gray-700">
                                                {logEntry.log}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserLogs;